@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.pricing_mainDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 2.2rem;

    @include respond-below(md) {
        flex-direction: column;
        padding-top: 0;
    }

    .pricing_subDiv {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid color("borderBlack");
        border-radius: 10px;
        margin: 0 5px;
        width: 350px;
        height: 451px;
        word-break: break-word;   
        position: relative;
        overflow: hidden;
        margin-bottom: 0.8rem;
        
        .innerDiv{
            height: 100%;
            position: relative;
            padding: 4rem 2rem 0 2rem;
            display: flex;
            justify-content: center;
        }

        @include respond-below(md) {
            width: 305px;
            margin: 10px 0;
        }

        .subscription_title {
            font-weight: 700;
            font-size: 22px;
            line-height: 33px;
            color: color("primaryYellow");
            margin-bottom: 4px;
        }

        .subscription_sub_title {
            font-weight: 400;
            font-size: 10px;
            line-height: 15px;
            text-decoration-line: underline;
            color: color("basicWhite");
            margin-bottom: 4px;
            min-height: 15px;
        }

        .subscription_price {
            font-weight: 400;
            font-size: 3rem;
            line-height: 4.5rem;
            color: color("basicWhite");
            margin-bottom: 4px;

            small {
                font-size: 2.2rem;
            }
        }

        .subscription_features_title {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: color("basicGrayDarker");
            margin-bottom: 4px;
        }

        .subscription_features_list {
            margin-bottom: 1.5rem;

            .subscription_feature {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: color("basicGrayDarker");
                margin-bottom: 4px;
            }
        }
    }
}

.buttonDiv {
    position: absolute;
    bottom: 20px;
    width: 100%;

    .buttonWidth {
        width: 100%;
    }
}


.tag {
    position: absolute;
    background: #fbb13c;
    transform: rotate(-45deg);
    top: 40px;
    left: -120px;
    width: 100%;
    display: flex;
    justify-content: center;
    color:color('basicWhite');

    @include respond-below(md){
        left:-100px
    }
}

