@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.content {
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;
    background-clip: padding-box;
    background-color: color('basicWhite');
    border: 0;
    border-radius: 20px;
    outline: 0 none;
    max-width: 90%;
    padding: 1.2rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    @include respond-below(mobile) {
        min-width: unset;
        max-width: 100%;
        width: 100%;
        height: 100%;
        border-radius: 0;
    }

    &.dark {
        background-color: color('darkBackground');
    }
}

.header {
    margin-bottom: 1.2rem;
}

.closeIcon {
    cursor: pointer;
}

.innerContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.body {
    flex: 1;
    display: flex;
}
