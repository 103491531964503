@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.advisorsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 3.75rem;
    grid-row-gap: 3.75rem;
    margin-top: 3.125rem;

    @include respond-below(mobile){
        display: flex;
        flex-direction: column;
        grid-row-gap: 1.875rem;
    }
}

.cardDark{
    color: color('basicWhite');
}

.cardLight{
    color: color('basicBlack');
}
.card {
    .cardContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border: 2px solid color("separatorGray");
        border-radius: 0.625rem;
        padding: 30px 20px 15px 20px;
        min-height: 600px;
        height: 100%;
    }

    .topPart{
        text-align: center;
    }

    .imageParent {
        .image {
            height: auto;
            max-width: 100%;
            max-height:11.688rem;
        }
    }
    
    .title {
        margin-top: 0.938rem;
        margin-bottom: 0.7rem;
        font-size: 2rem;
        font-weight: 700;
        line-height: 3rem;
        letter-spacing: 0px;
        text-align: center;   
    }

    .role{
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        letter-spacing: 0px;
        text-align: center;
        margin-bottom: 10px;
    }

    .icon{
        margin-bottom:10px;

        .size{
       
            height: 22px;
            width: 22px;
        }
    }

    .iconLight{
        path{
            fill:black;
        }
    }

    .description {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        text-align: justify;
        padding:0 0.5rem 2.5rem 0.5rem;
    }

    .logo{
        max-height: 4rem;
        max-width: 100%;
    }
}
