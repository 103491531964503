@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.technology_mainDiv {
    display: flex;
    flex-direction: row;

    @include respond-below(md){
        flex-direction: column;
    }

    .technology_leftDiv {
        flex: 1;
        margin-right: 5rem;

        @include respond-below(md){
            margin-right: 0;
        }

        .technology_image {
            width: 100%;
            height: auto;
        }
    }

    .technology_rightDiv {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-left: 5rem;

        @include respond-below(md){
            margin-left: 0;
            margin-top:1rem;
        }
    }

    .technology_description {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 2rem;
        margin-bottom: 2.188rem;

        @include respond-below(md) {
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }

    .technology_descriptionLight {
        color: color('basicBlack');
    }

    .technology_descriptionDark {
        color: color('basicWhite');

        @include respond-below(md) {
            line-height: 2.25rem;
        }
    }
}

