@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/theme/colors.scss";

.button {
    border-radius: 10px;
    font-weight: 700;
    font-size: 0.95rem;
    padding: 0.9rem 1.5rem;
    cursor: pointer;
}
