@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/responsive.scss";
@import "../../../../styles/theme/colors.scss";

$separatorPadding: 4rem;

.form {
    display: flex;
    flex-direction: row;

    @include respond-below(mobile) {
        flex-direction: column;
    }

    .leftSide {
        flex: 1;
        padding-right: $separatorPadding;

        @include respond-below(mobile) {
            padding-right: 0;
            padding-bottom: $separatorPadding;
        }
    }

    .rightSide {
        padding: 4rem 2rem;
        min-width: 26rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-left: 1px solid color('separatorGray');

        @include respond-below(lg) {
            min-width: 0;
            text-align: center;
        }

        @include respond-below(mobile) {
            border-left: 0;
            border-top: 1px solid color('separatorGray');
            padding: 0;
            padding-top: 2rem;
            min-width: 0;
        }
    }
}

.value {
    font-weight: 700;
    font-size: 2.6rem;
    color: color('primaryGreen');
}

.valueUnit {
    font-weight: 700;
    font-size: 1.3rem;
    color: color('primaryGreen');
}

.valuePer {
    font-weight: 700;
    font-size: 1.1rem;
    color: color('basicBlack');
}

.infoText {
    font-size: 1.3rem;
    line-height: 1.2rem;
    color: color('basicBlack');
}

.buyButton {
    margin-top: 2rem;
}
