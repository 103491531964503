@import "../../../../../styles/utils/mixins.scss";
@import "../../../../../styles/utils/functions.scss";
@import "../../../../../styles/theme/colors.scss";

.container {
    width: 7.4rem;
    cursor: pointer;
    border: 2px solid color('transparent');
    border-radius: 12px;
    display: flex;

    &.selected {
        border: 2px solid color('primaryGreen');
    }
}

.innerContainer {
    flex: 1;
    padding: 0.2rem;
    padding-top: 0.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid color('basicGrayDarker');
    border-radius: 12px;

    &.selected {
        border: 1px solid color('transparent');
    }
}

.name {
    font-weight: 500;
    font-size: 0.68rem;
    line-height: 1rem;
    text-align: center;
    margin-top: 0.6rem;
}
