@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.grid {
    display: flex;
    flex-direction: row;
    margin: 7rem 0 5rem 0;
    align-items: center;

    &.gridLight {
        min-height: 700px;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.rightDiv {
    display: flex;
    flex:1;
    flex-direction: column;
    padding-left: 1rem;

    .item {
    
        .description {
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.5rem;
            color: color("darkBackground");
            padding-top: 0.5rem;
            text-align: left;

            &.darkDescription {
                color: color('basicWhite') !important
            }
        }
    }

    .closedItem {
        margin: 1.875rem 2.5rem 0 2.5rem;
        padding-bottom: 1.875rem;
        border-bottom: 1px solid color("borderBlack");
        text-align: left;
        cursor: pointer;
        // transition: 0.2s ease-in-out;

        .title {
            font-weight: 400;
            font-size: 1.375rem;
            color: color("darkBackground");

            &.titleDark {
                color: color("selectorDark");
            }
        }

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            border: none;
        }
    }
    
    .openItem {
        display: flex;
        text-align:left;
        flex-direction: column;
        border: 1px solid color("borderBlack");
        border-radius: 0.625rem;
        padding: 1.813rem 2.5rem;
        overflow: hidden;

        .title {
            font-weight: 700;
            font-size: 26px;
            line-height: 60px;
            color: color("primaryGreen");

            &.titleDark {
                color: color("primaryYellow");
            }
        }
    }
}

.leftDiv {
    display: flex;
    flex:1;
    padding-right: 1rem;
    justify-content: center;

    .webImage {
        max-width: 100%;
        background-size: cover;
        height: auto;
        max-height:40rem;
    }
}
