@import "../../../../../styles/utils/mixins.scss";
@import "../../../../../styles/utils/functions.scss";
@import "../../../../../styles/utils/responsive.scss";
@import "../../../../../styles/theme/colors.scss";

.container {
    border: 1px solid color('resultGraphBoxBorder');
    border-radius: 10px;
    padding: 1.2rem;
    margin-bottom: 1rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.circle {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: color('basicBlack');
    border-radius: 10px;
    margin-right: 0.4rem;
}

.name {
    display: inline-block;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: color('basicBlack');
}

.priceContainer {
    text-align: right;
}

.priceValue {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.9rem;
    color: color('basicBlack');
}

.priceUnit {
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.2rem;
    color: color('basicBlack');
}

.pricePer {
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.2rem;
    color: color('basicBlack');
}
