@import "../../../styles/utils/responsive.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/theme/colors.scss";

.modal {
    width: 540px;
    height: 650px;

    @include respond-below(mobile) {
        width: 100%;
        height: 100%;
    }
}

.container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.logoContainer {
    text-align: center;
    margin-bottom: 1.4rem;

    @include respond-below(mobile) {
        margin-bottom: 0;
    }
}

.iframeContainer {
    flex: 1;
    position: relative;
}

.iframe {
    width: 100%;
    height: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}
