@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.8rem;

    @include respond-below(mobile) {
        flex-direction: column;
    }
}

.rowContainer {
    width: 100%;
    flex: 1;
    position: relative;
}

.sliderContainer {
    margin-bottom: 1.4rem;
}

.slider {
    padding-top: 1rem !important;

    :global {
        .rc-slider-rail {
            height: 9px;
            background: color('basicGrayDarker');
            cursor: pointer;
        }

        .rc-slider-track {
            background: color('primaryGreen');
            height: 9px;
            cursor: pointer;
        }

        .rc-slider-handle,
        .rc-slider-handle-dragging {
            background: color('basicWhite') !important;
            opacity: 1 !important;
            border: 4px solid color('primaryGreen') !important;
            height: 19px !important;
            width: 19px !important;
            box-shadow: 0px 3.753px 9.3825px rgba(0, 0, 0, 0.6) !important;
        }
    }
}

.minLabel {
    position: absolute;
    left: 5px;
    bottom: 0;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 1rem;
}

.maxLabel {
    position: absolute;
    right: 5px;
    bottom: 0;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 1rem;
}

.valueContainer {
    margin-left: 1rem;
    border: 1px solid color('basicBlack');
    border-radius: 12px;
    padding: 0.6em;
    color: color('basicBlack');
    margin-top: -6px;
    width: 120px;
    text-align: center;

    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem;

    @include respond-below(mobile) {
        margin-left: 0;
        padding: 0.4rem 0.6em;
    }
}
