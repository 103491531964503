@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.swiperContainerLight {
    margin-bottom: 2rem;

    :global {
        .swiper-pagination-clickable {
            text-align: center;
        }

        .swiper-pagination-bullet {
            border-radius: 30%;
            width: 4px;
            height: 10px;
            background-color: color('basicGrayDarker');
            position: relative;
            top: 1.5px;
            opacity: 1;
        }

        .swiper-pagination-bullet-active {
            background-color: color('primaryGreen');
            border-radius: 20%;
            width: 4px;
            height: 14px;
            top: 3px;
            opacity: 1;
        }
  
    }
}

.swiperContainerDark {
    margin-bottom: 2rem;

    :global {
        .swiper-pagination-clickable {
            text-align: center;
        }

        .swiper-pagination-bullet {
            border-radius: 30%;
            width: 4px;
            height: 10px;
            background-color: color('basicGrayDarker');
            position: relative;
            top: 1.5px;
            opacity: 1;
        }

        .swiper-pagination-bullet-active {
            background-color: color('primaryYellow');
            border-radius: 20%;
            width: 4px;
            height: 14px;
            top: 3px;
            opacity: 1;
        }
  
    }
}


.pagination{
    padding-top:0.5rem
}

.mobileSlide {
    position: relative;

    .slideContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid color("borderBlack");
        border-radius: 0.625rem;
    }

    .mobileImageParent {
        padding: 1rem 0.5rem 0 0.5rem;
        margin-bottom: 1rem;
        position: relative;

        .mobileImage {
            height: auto;
            max-width: 100%;
            max-height:40vh;
        }
    }
    
    .mobileTitle {
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.875rem;
        color: color("primaryGreen");
        margin-top: 0.938rem;
        margin-bottom: 0.938rem;
    }

    .mobileDescription {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        text-align: center;
        color:color('basicBlack');
        padding:0 0.5rem 2.5rem 0.5rem;
    }
}

.darkDescription{
    color: color('basicWhite') !important
}

.darkTitle{
    color: color('primaryYellow') !important;
}