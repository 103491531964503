@import "../../../../../styles/utils/mixins.scss";
@import "../../../../../styles/utils/functions.scss";
@import "../../../../../styles/utils/responsive.scss";
@import "../../../../../styles/theme/colors.scss";

.container {
    display: flex;
    flex-direction: row;

    @include respond-below(mobile) {
        flex-direction: column;
    }
}

.graph {
    flex: 1;

    @include respond-below(mobile) {
        border: 1px solid color('resultGraphBoxBorder');
        border-radius: 10px;
        margin-bottom: 1rem;
    }
}

.values {
    width: 30%;
    display: flex;
    flex-direction: column;

    @include respond-below(mobile) {
        width: 100%;
    }
}
