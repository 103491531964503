@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.container {
    position: relative;

    &.containerLight{
        padding-bottom: 6rem;

        @include respond-below(mobile) {
            padding-bottom: 23rem;
        }
    }

    &.containerDark {
        padding-bottom: 10rem;

        @include respond-below(mobile) {
            padding-bottom: 1rem;
        }
    }
}

.innerContainer {
    position: relative;
}

.logoContainer {
    text-align: center;
    padding: 2rem 0;
}

.toggleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;

    @include respond-below(mobile) {
        margin-top: 2.5rem;
    }

    .toggleLabel {
        font-weight: 700;
        font-size: 1.5rem;
        text-transform: uppercase;
        color: color('basicBlack');

        &.darkMode {
            color: color('basicWhite');
        }

        @include respond-below(mobile) {
            font-size: 1.1rem;
        }
    }

    .toggle {
        margin: 0 1rem;
    }
}

.titleWrapper {
    display: flex;
}

.titleContainer {
    width: 50%;
    margin-top: 5rem;
    display: inline-block;

    @include respond-below(mobile) {
        width: 100%;
        text-align: center;
        margin-top: 3.8rem;
    }

    .title {
        font-weight: 700;
        font-size: 3.7rem;
        line-height: 4.2rem;
        margin-bottom: 0.4rem;

        @include respond-below(mobile) {
            font-size: 2.6rem;
            line-height: 3rem;
        }

        &.titleLight {
            color: color('basicBlack');

            b {
                color: color('primaryGreen');
            }
        }

        &.titleDark {
            color: color('basicWhite');

            b {
                color: color('primaryYellow');
            }
        }
    }

    .description {
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 2.5rem;

        @include respond-below(mobile) {
            font-size: 1rem;
            line-height: 1.4rem;
        }

        &.descriptionLight {
            color: color('basicBlack');
        }

        &.descriptionDark {
            color: color('basicWhite');
        }
    }
}

.headerButtonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4.4rem;

    @include respond-below(mobile) {
        flex-direction: column;
        margin-top: 2.4rem;

        button {
            margin-bottom: 1rem;
        }
    }
}

.backgroundLight {
    position: absolute;
    right: 0;
    top: 0;
    max-width: 680px;
}

.backgroundMobileLight {
    position: absolute;
    max-width: 100%;
    right: 0;
    bottom: -2rem;
}

.backgroundDarkContainer {
    width: 50%;
    position: relative;
    display: inline-block;
}

.backgroundDark {
    position: absolute;
    top: -2rem;
    max-width: 560px;
}

.backgroundMobileDark {
    max-width: 100%;
    text-align: center;
}

.backgroundMobileImg{
    max-width: 78%;
}
