@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/responsive.scss";
@import "../../../../styles/theme/colors.scss";

.records {
    display: flex;
    flex-direction: row;

    @include respond-below(mobile) {
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 0.6rem;
    }

    .recordItem {
        margin-right: 0.8rem;
        margin-top: 0.4rem;

        &:last-child {
            margin-right: 0;

            @include respond-below(mobile) {
                margin-right: 0.8rem;
            }
        }
    }
}

.title {
    text-align: center;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 3.75rem;
    color: color('basicBlack');
    margin-top: 4rem;
    margin-bottom: 1.8rem;

    @include respond-below(mobile) {
        font-size: 1.3rem;
        line-height: 1.8rem;
        margin-top: 2rem;
        margin-bottom: 0.6rem;
    }
}

.infoIcon {
    margin-left: 0.4rem;
    cursor: pointer;
}

.infoTooltip {
    z-index: 100;
    background-color: color('tooltip') !important;
    color: color('basicBlack') !important;
    font-size: 0.85rem !important;
    line-height: 1.5rem !important;
    opacity: 1 !important;
    padding: 1rem 0.8rem !important;

    span {
        position: relative;
        z-index: 150;
    }
}

.infoTooltipArrow {
    width: 30px !important;
    height: 30px !important;
}

.usageNote {
    margin-top: 1.5rem;
    margin-bottom: 0.4rem;
    color: color('basicBlack');
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.3125rem;
}

.recordImage{
    max-width: 100%;
    background-size: cover;
    height: auto;
    max-height:8vh;
}