@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/functions.scss";
@import "../../styles/utils/responsive.scss";
@import "../../styles/theme/colors.scss";

.container {
    color: color('basicBlack');
    position: relative;
    overflow: hidden;

    &.containerDark {
        background-color: color('fanBackground');
        color: color('basicWhite');
    }
}

.logoContainer {
    text-align: center;
    padding: 2rem 0;
}

.section {
    margin-top: 2rem;
    margin-bottom: 8rem;

    @include respond-below(mobile) {
        margin-bottom: 2rem;
        padding: 1.2rem;
    }
}

.content {
    font-style: normal;

    h1{
        font-weight: 700;
        font-size: 2rem;
        line-height: 3rem;
    }

    h2{
        margin-left: 5rem;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2.25rem;

        @include respond-below(mobile) {
            margin-left: 0;
        }
    }

    p {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.3125rem;
    }
}

.darkItem a {
    color: color('basicWhite');
}

.lightItem a {
    color: color('basicBlack');
}
