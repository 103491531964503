@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.container {
    margin: 8rem 0;

    @include respond-below(mobile) {
        margin: 3.6rem 0;
    }
}

.title {
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.75rem;
    color: color('basicBlack');
    text-align: center;
    margin: 1.6rem 0;

    @include respond-below(mobile) {
        font-size: 1.75rem;
        line-height: 2.65rem;
    }
}

.darkMode{
    color: color('basicWhite');

    b {
        color: color('primaryYellow');
    }
}
