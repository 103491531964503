@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.background {
    background-color: color("darkBackground");
    position: relative;
}

.fade {
    height: 8rem;
    width: 100%;
    background: linear-gradient(180deg, color("fanBackground") 0%, color("darkBackground") 100%);
    position: absolute;
    z-index: 2;
}

.container {
    position: relative;
    padding-top: 2rem;
    padding-bottom: 6rem;
    z-index: 5;
    display: flex;
    flex-direction: column;

    @include respond-below(mobile) {
        padding-bottom: 4rem;
        // padding-left: 2rem;
        // padding-right: 2rem;
    }
}

.title {
    font-size: 2.625rem;
    font-weight: 700;
    line-height: 3.75rem;
    color: color('basicWhite');
    text-align: center;
    padding: 1.6rem 0;
    padding-bottom: 5rem;

    @include respond-below(mobile) {
        font-size: 2.25rem;
        line-height: 3.75rem;
        padding-bottom: 4.5rem;
    }

    b {
        color: color('primaryYellow');
    }
}

.image {
    max-width: 100%;
    padding-bottom: 3.5rem;
}

.grid {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 1fr);
    gap: 34px;

    @include respond-below(mobile) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.column {
    @include respond-below(mobile) {
        text-align: center;
    }

    .name {
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: color('primaryYellow');
        margin-bottom: 0.8rem;

        @include respond-below(mobile) {
            font-size: 1.5rem;
            line-height: 2.25rem;
        }
    }

    .text {
        font-weight: 400;
        font-size: 0.95rem;
        line-height: 1.5rem;
        color: color('basicWhite');

        @include respond-below(mobile) {
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }
}

