@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.waves {
    position: absolute;
    background-repeat: no-repeat;
    // height: 100%;
    width: 100%;
    pointer-events: none;
    z-index: 1;
}

.wavesMobile {
    position: absolute;
    background-repeat: no-repeat;
    // height: 600px;
    width: 100%;
    margin-top: 14rem;
    pointer-events: none;
}