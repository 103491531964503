@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.ecosystem_description {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.25rem;
    color: color("lightBlack");
    text-align: center;

    @include respond-below(md) {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

.ecosystem_image {
    width: 100%;
    height: auto;
}

.web_margin {
    margin-top: 5rem;
}

.mobile_margin {
    margin-top: 1rem;
}
