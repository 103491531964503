@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.backgroundLight {
    background-color: color('sponsorsGrey');
}

.backgroundDark {
    background-color: color('transparent');
    padding-bottom: 6.8rem;

    @include respond-below(md) {
        padding-bottom: 2rem;
    }
}

.flexRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 1rem 0;

    @include respond-below(md) {
        flex-direction: column;
        padding: 0.5rem 0;

        img {
            padding-bottom: 1.6rem;
        }
    }
}

.sponsorLogo{
    max-width: 250px;
    background-size: cover;
    height: auto;
    max-height:13vh;
}