@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.container {
    padding: 2rem 0;

    @include respond-below(mobile) {
        padding: 2rem 1.4rem;
    }

    &.containerLight {
        background-color: color('primaryGreen');
    }

    &.containerDark {
        background-color: color('fanBackground');
        border-top: 1px solid color('borderBlack');
    }
}

.information {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: color('basicWhite');
    margin-bottom: 6rem;

    @include respond-below(mobile) {
        flex-direction: column;
        margin-bottom: 2rem;
    }

    a {
        color: color('basicWhite');
    }

    .column {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-right: 1.8rem;

        @include respond-below(mobile) {
            padding-right: 0;
            margin-bottom: 3rem;
        }

        &:last-child {
            padding-right: 0;

            @include respond-below(mobile) {
                margin-bottom: 0;
            }
        }
    }

    .logoContainer {
        flex: none;
        margin-right: 3rem;

        @include respond-below(mobile) {
            justify-content: center;
            align-items: center;
            margin-right: 0;
        }
    }

    .label {
        font-weight: 700;
        font-size: 0.9rem;
        line-height: 1.25rem;
        text-transform: uppercase;
        margin-bottom: 0.9rem;

        &.labelDark {
            color: color('selectorDark');
        }
    }

    .text {
        font-size: 0.9rem;
        line-height: 1.375rem;
    }

    .link {
        font-size: 0.9rem;
        line-height: 1.375rem;
        text-decoration: none;
        margin-bottom: 1rem;
    }
}

.socialIcons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.4rem;
}

.bottom {
    color: color('basicWhite');
    display: flex;
    flex-direction: row;
    align-items: center;

    @include respond-below(mobile) {
        flex-direction: column-reverse;
    }

    .copyrightText {
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 1.125rem;
        flex: 1;
        color: color('basicWhite');

        &.copyrightTextDark {
            color: color('selectorDark');
        }

        @include respond-below(mobile) {
            margin-top: 3rem;
            text-align: center;
        }
    }
}
