@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.content{
    text-align: center;
    
    @include respond-below(mobile) {
        padding-bottom: 1.4rem;
    }
}

.whiteText{
    color: color('basicWhite') ;
}