@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/responsive.scss";
@import "../../../../styles/theme/colors.scss";

.note {
    margin-top: 1rem;
    color: color('basicBlack');
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.3125rem;
}

.program {
    text-align: center;
}

.title {
    text-align: center;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 3.75rem;
    color: color('basicBlack');
    margin-top: 4rem;
    margin-bottom: 2rem;

    @include respond-below(mobile) {
        font-size: 1.3rem;
        line-height: 1.875rem;
        margin-bottom: 3rem;
    }
}

.text {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.6rem;
    text-align: center;
    color: color('basicBlack');
    margin-bottom: 2rem;

    @include respond-below(mobile) {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

.usageNote {
    margin-top: 1.5rem;
    margin-bottom: 0.4rem;
    color: color('basicBlack');
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.3125rem;
}
