@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.container {
    @include respond-below(mobile) {
        padding: 0;
    }
}

.innerContainer {
    background-color: color('basicGray');
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 2.6rem;

    @include respond-below(mobile) {
        padding: 1.8rem;
    }
}

.tabsButtons {
    display: flex;
    flex-direction: row;

    @include respond-below(mobile) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 0.6rem;
    }

    .tabButton {
        margin-right: 0.8rem;
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 1.0625rem;

        @include respond-below(mobile) {
            margin-right: 0;
            margin-bottom: 0.8rem;
            width: 76%;
        }

        &:last-child {
            margin-right: 0;

            @include respond-below(mobile) {
                margin-bottom: 0;
            }
        }
    }
}
