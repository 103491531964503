@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.label {
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.3rem;
    color: color('basicBlack');
    padding-top: 1rem;
    display: inline-block;
}
