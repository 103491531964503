@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.container {
    @include respond-below(mobile) {
        margin-top: 0;
    }
}

.content {
    text-align: center;
}

.video {
    border-radius: 10px;
    aspect-ratio: 16 / 9;
    width: 100%;

    @include respond-above(md) {
        max-width: 680px;
    }
}

.description {
    font-size: 1rem;
    line-height: 2.25rem;
    color: color('basicBlack');
    margin-top: 0.8rem;

    @include respond-below(mobile) {
        line-height: 1.5rem;
    }

    @include respond-above(md) {
        max-width: 680px;
        margin-left: auto;
        margin-right: auto;
    }
}
